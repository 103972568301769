import { FC, ReactFragment } from "react";
import { useHistory } from "react-router-dom";
import "./footer.css";

/** Footer common for all screens */
const Footer: FC = () => {
  const history = useHistory();

  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="row footer-link">
            {/* Column1 */}
            <div className="col">
              <h4 id="footer-col-heading">Contact Us/Support</h4>
              <ul className="list-unstyled">
                <li>4650 Sunset Blvd. #138</li>
                <li>Los Angeles, CA 90027</li>
                <li><a className="footer-email" rel="noopener noreferrer" href="mailto:help@chla.usc.edu">help@chla.usc.edu</a></li>
                <li>888.631.2452, option 3</li>
              </ul>
            </div>
            {/* Column2 */}
            <div className="col">
              <h4 id="footer-col-heading">Patient Resources</h4>
              <ul className="list-unstyled">
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href="https://www.chla.org/blog/all"
                    target="_blank">
                    RN Remedies
                  </a>

                </li>
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href=" https://www.chla.org/imagine"
                    target="_blank">
                    Imagine Magazine
                  </a>

                </li>
              </ul>
            </div>
            {/* Column3 */}
            <div className="col">
              <h4 id="footer-col-heading">Physician Newsletter</h4>
              <ul className="list-unstyled">
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href="https://www.chla.org/physician-enews-signup"
                    target="_blank">
                    Sign up for the eNewsletter
                  </a>

                </li>
              </ul>
            </div>
            {/* Column4 */}
            <div className="col">
              <h4 id="footer-col-heading">Terms & Conditions</h4>
              <ul className="list-unstyled">
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href="https://www.chla.org/terms-use"
                    target="_blank">
                    General Terms of Use
                  </a>
                </li>
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href={`${window.location.origin}/home/terms`}
                    target="_blank">
                    myCHLA Terms of Use
                  </a>
                </li>
                <li>
                  <a className="footer-email" rel="noopener noreferrer" href={`${window.location.origin}/home/privacypolicy`}
                    target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-version">
          <div className="col-sm  container footer-main">
            &copy;{new Date().getFullYear()} Children's Hospital Los Angeles{" "}
            <span>Version 3.3.3</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
