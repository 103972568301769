import { FC, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Button, ButtonGroup, Table, ToggleButton } from "react-bootstrap";
import ChartService from "../../../../services/ChartService";
import IPatientLabResults, {
  EVENT,
  EVENTNOTE,
  LAB,
} from "../../../../types/IPatientLabResults";
import Loader from "../../../common/Loader";
import IPatient from "../../../../types/IPatient";
import { useReactToPrint } from "react-to-print";
import ComponentToPrintLabs from "./ComponentToPrintLabs";
import "./lab-results.css";
import CustomScale from "../../../customScale/CustomScale";
import CommentsModal from "./CommentsModal";
import { firstBy as orderBy } from "thenby";
import MButton from "../../../mButton/MButton";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../../../helpers/DateHelper";
import CustomAccordion from "../../../accordion/CustomAccordion";
import { appInsights } from "../../../../appInsights";
import axios from "axios";

type stateType = {
  state: {
    patient: IPatient;
  };
};
type ES2 = {
  name: string;
  labs?: LAB[];
};
type ES1 = {
  name: string;
  ES2s?: ES2[];
};

const LabResults: FC<{
  personId: string;
  encounterId: string;
  lookback: string;
}> = ({ personId, encounterId, lookback }) => {
  const [loading, setLoading] = useState(true);
  const [labResults, setLabResults] = useState<IPatientLabResults>();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const { state } = useLocation<stateType>();
  const patientDetail = state.state.patient;
  const componentRef = useRef(null);
  const pageStyle = `{ size: 2.5in 4in }`;
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();
  let titleDate = `${year}${month}${day}`;
  const documentTitle = `CHLA_${patientDetail.patientName} - ${titleDate}`;
  const [resultFilter, setResultFilter] = useState<"date" | "type">("date");
  const [open, setOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [eventName, setEventName] = useState<string>();
  const [notes, setNotes] = useState<EVENTNOTE[]>();
  const [filteredByType, setFilteredByType] = useState<ES1[]>([]);

  useEffect(() => {
    if (labResults !== undefined ) {
      let allLabs = labResults.RPTDATA.LABS[0].LAB.map((lab) => lab);
      allLabs.sort(
        orderBy((a: LAB, b: LAB) => a.ES1_SEQ - b.ES1_SEQ).thenBy(
          (a: LAB, b: LAB) => a.ES2_SEQ - b.ES2_SEQ
        )
      );
      let lastES1 = 0;
      let lastES2 = 0;

      let ES1s: ES1[] = [];
      let currentES1: ES1;
      let currentES2: ES2;

      allLabs.forEach((lab) => {
       
        if (lab.ES1_SEQ > lastES1) {
          currentES1 = {
            name: lab.ES1,
            ES2s: [],
          };
          ES1s.push(currentES1);
        }
        if (lab.ES1_SEQ > lastES1 || lab.ES2_SEQ > lastES2) {
          currentES2 = {
            name: lab.ES2,
            labs: [],
          };
          currentES1.ES2s?.push(currentES2);
        }
        currentES2.labs?.push(lab);
        lastES1 = lab.ES1_SEQ;
        lastES2 = lab.ES2_SEQ;
      });
      setFilteredByType(ES1s);
    }
  }, [labResults]);

  const handlePrint = useReactToPrint({
    pageStyle: () => pageStyle,
    content: () => componentRef.current,
    documentTitle: documentTitle,
  });

  useEffect(() => {
    setLoading(true);
    ChartService.getLabResults(
      {
        personId: personId,
        lookback: lookback,
        encounterId: encounterId,
      },
      accessToken,
      cancelToken
    )
      .then((res) => {
        setLabResults(res.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
        
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [encounterId, lookback]);

  const handleCommentsClick = (event: EVENT) => {
    setShowModal(true);
    setEventName(event.DISPLAY);
    setNotes(event.EVENT_NOTES);
  };

  const renderEventsTable = (lab: LAB) => {
    return (
      <Table id="lab-table">
        <tbody>
          {lab.EVENT.map((event) => {
            return (
              <tr key={event?.EVENT_ID}>
                <td width={300}>
                  <div style={{ flexDirection: "column" }}>
                    <b>{event.DISPLAY}</b>
                    <div style={{ fontSize: "small" }}>
                      {event.RESULT_UNITS && event.RESULT_UNITS}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      {event.RESULT_STATUS}
                    </div>
                  </div>
                </td>
                <td width={800}>
                  <CustomScale
                    value={event.RESULT_VAL}
                    criticalLow={event.CRITICAL_LOW}
                    criticalHigh={event.CRITICAL_HIGH}
                    normalLow={event.NORMAL_LOW}
                    normalHigh={event.NORMAL_HIGH}
                    normalcy={event.NORMALCY}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  {event.EVENT_NOTE_CNT > 0 ? (
                    <MButton
                      onClick={() => handleCommentsClick(event)}
                      title="Comments"
                      variant="primary"
                      width={"fit-content"}
                    />

                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderResultsByDate = () => {
    return labResults?.RPTDATA.LABS.map(({ LAB }) => {
      if (LAB.length === 0) {
        return <h5>No record found</h5>;
      }
      return LAB.map((lab) => {
        return (
          <div>
            <h5 id="patient-detail-lab-name">{lab.ES2}</h5>
            <div className="mt-2">
              <h6>Collected: {lab.EVENT_END_DT_TM.slice(0, -3)}</h6>
              <h6>Ordered By: {lab.ORDERING_PHYSICIAN}</h6>
            </div>
            {renderEventsTable(lab)}
          </div>
        );
      });
    });
  };

  const renderResultsByType = () => {
    if (filteredByType.length === 0) {
      return <h5>No record found</h5>;
    }
    return filteredByType.map((ES1) => {
      return (
        <>
          <details open={open} style={{ margin: "1rem 0 1rem 0" }}>
            <summary id="patient-detail-lab-name" style={{ fontSize: "1rem" }}>
              {ES1.name} {"(" + ES1.ES2s?.length + ")"}
            </summary>
            {ES1.ES2s?.map((ES2) => {
              return (
                <details open={open} style={{ margin: "1rem 0 1rem 1rem" }}>
                  <summary className="filter-by-type-sub-heading">
                    {ES2.name} {"(" + ES2.labs?.length + ")"}
                  </summary>
                  {ES2.labs?.map((lab) => {
                    return (
                      <details
                        open={open}
                        style={{ margin: "1rem 0 1rem 1rem" }}
                      >
                        <summary>
                          {lab.EVENT_END_DT_TM.slice(0, -3)} (Collected)
                        </summary>
                        <br />
                        Ordered By: {lab.ORDERING_PHYSICIAN}
                        {renderEventsTable(lab)}
                      </details>
                    );
                  })}
                </details>
              );
            })}
          </details>
          <hr />
        </>
      );
    });
  };

  let isDisabled;
  if (loading) {
    isDisabled = true;
  } else {
    labResults?.RPTDATA.LABS.map(({ LAB }) => {
      if (LAB.length === 0) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
    });
  }

  return (
    <div id="lab-results">
      <CustomAccordion
        title={
          <div className="header-top">
            <h4 className="header-blue bold m-0 pe-2">Lab Results</h4>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                (
                {labResults !== undefined
                  ? labResults.RPTDATA.LABS[0].LAB_CNT
                  : 0}
                )
              </h5>
            )}
          </div>
        }
        content={
          <>
            <div className="action">
              <ButtonGroup size="sm">
                <ToggleButton
                  type="radio"
                  value="date"
                  name="date"
                  checked={resultFilter === "date"}
                  variant="outline-secondary"
                  onClick={() => setResultFilter("date")}
                >
                  By Date
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  value="type"
                  name="type"
                  checked={resultFilter === "type"}
                  variant="outline-secondary"
                  onClick={() => {
                    setResultFilter("type");
                  }}
                >
                  By Type
                </ToggleButton>
              </ButtonGroup>
              <MButton
                variant="primary"
                onClick={handlePrint}
                disabled={isDisabled}
               
                title="Print"
                width={"fit-content"}
              />
            </div>
            <>
              <strong>Note:</strong> This is not a comprehensive list of all tests performed on this patient. The following results are not included: Anatomic pathology, bacteriology, HLA, microbiology, molecular pathology, mycology, report images, scanned reports, surgical pathology.{" "}
            </>
            {resultFilter === "type" && filteredByType.length !== 0 && (
              <>
                <div
                  className="toggle-btn"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {open ? "Collapse" : "Expand"} All
                </div>
              </>
            )}
            <hr style={{marginTop: '5px'}} id="detail-patient-divider" />
            <>
              <div style={{ display: "none" }}>
                <div ref={componentRef}>
                  <ComponentToPrintLabs
                    labResults={labResults!}
                    lookback={lookback}
                    result="labResult"
                  />
                </div>
              </div>
            </>
            <div>
              {loading ? (
                <></>
              ) : 
                resultFilter === "date" ? (
                  renderResultsByDate()
                ) : (
                  renderResultsByType()
                )}
            </div>
          </>
        }
      />
      <CommentsModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setEventName(undefined);
          setNotes(undefined);
        }}
        notes={notes}
        eventName={eventName}
      />
    </div>
  );
};

export default LabResults;
