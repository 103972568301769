import { FC } from "react";
import { Modal } from "react-bootstrap";
import MButton from "../mButton/MButton";

const CustomAlertModal: FC<{
    show: boolean;
    header?: string;
    body: string;
    hideBorder?: boolean;
    handleClose?: () => void;
}> = ({ show, header, body, hideBorder, handleClose, }) => {

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className={hideBorder?"modal-border-hidden":""}>{header}</Modal.Header>
            <Modal.Body className={hideBorder?"modal-border-hidden":""}>
                {body}
            </Modal.Body>
            <Modal.Footer className={hideBorder?"modal-border-hidden":""}>
                <MButton
                    onClick={handleClose}
                    variant="primary"
                    title="Ok"
                    width={"max-content"}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default CustomAlertModal;
