import React from "react";
import { Modal, Button } from "react-bootstrap";
import { NotesErrorMessage } from "../../../../types/IPatientNotes";

interface NoteViewErrorModalProps {
  show: boolean;
  onClose: () => void;
  onTryAgain: (format: string) => void;
  formatFailed: string;
  errorMessage?: NotesErrorMessage;
}

const NoteViewErrorModal: React.FC<NoteViewErrorModalProps> = ({
  show,
  onClose,
  onTryAgain,
  formatFailed,
  errorMessage,
}) => {
  const getAlternativeMethod = (failedMethod: string) => {    
    const methodMap: { [key: string]: string } = {
      XR: "HTML TR",
      TR: "PDF XR",
    };
    return methodMap[failedMethod] || ""; 
  };

  const alternativeMethod = getAlternativeMethod(formatFailed);
  const [alternative, method] = alternativeMethod.split(" ");

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="modal-border-hidden"></Modal.Header>
      <Modal.Body className="modal-border-hidden responsive-text">
        <p>
          {formatFailed === "XR"
            ? errorMessage?.pdfError
            : errorMessage?.htmlError}
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-border-hidden">
        <Button
          variant="secondary"
          onClick={onClose}
          className="modal-button"
          style={{ borderRadius: "20px", border: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => onTryAgain(method)}
          className="modal-button"
          style={{
            borderRadius: "20px",
            backgroundColor: "var(--color-light-blue)",
            border: "none",
          }}
        >
          {`View as ${alternative}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteViewErrorModal;
