import { useOktaAuth } from "@okta/okta-react";
import { FC, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import ChartService from "../../../../services/ChartService";
import IPatientRadiologyResults, {
  EVENT,
} from "../../../../types/IPatientRadiologyResults";
import Loader from "../../../common/Loader";
import ReportsModal from "./ReportsModal";
import "./radiology-results.css";
import CustomAccordion from "../../../accordion/CustomAccordion";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
import MButton from "../../../mButton/MButton";

const RadiologyResults: FC<{
  personId: string;
  encounterId?: string;
  lookback: string;
}> = ({ personId, encounterId, lookback }) => {
  const [loading, setLoading] = useState(true);
  const [radiologyResults, setRadiologyResults] =
    useState<IPatientRadiologyResults>();
  const [event, setEvent] = useState<EVENT>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const aref = useRef<HTMLAnchorElement>(null);
  const [open, setOpen] = useState<boolean>(true);

  const handleReportsClick = (event: EVENT) => {
    setShowModal(true);
    setEvent(event);
  };

  useEffect(() => {
    setLoading(true);
    ChartService.getRadiologyResults(
      {
        personId: personId,
        lookback: lookback,
        encounterId: encounterId,
      },
      accessToken,
      cancelToken
    )
      .then((res) => {
        setRadiologyResults(res.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [encounterId, lookback]);

  return (
    <div id="radiology-results">
      <CustomAccordion
        title={
          <div className="header-top">
            <h4 className="header-blue bold m-0 pe-2">Radiology Results</h4>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                (
                {radiologyResults !== undefined
                  ? radiologyResults.RPTDATA.EVENTS_CNT
                  : 0}
                )
              </h5>
            )}
          </div>
        }
        content={
          <>
            <div>
              {loading ? (
                <></>
              ) : // <Loader />
              radiologyResults?.RPTDATA.EVENTS_CNT === 0 ? (
                <h5>No record found</h5>
              ) : (
                <Table id="responsive-table-radiology">
                  <thead>
                    <tr>
                      <th>Visit Date</th>
                      <th>Reports</th>
                      <th>Images</th>
                    </tr>
                  </thead>
                  <tbody>
                    {radiologyResults?.RPTDATA.EVENTS.map((event) => {
                      return (
                        <tr key={event?.EVENT_ID}>
                          <td>{event.EVENT_VISIT_DATE}</td>
                          <td
                            id="table-reports-radiology"
                            onClick={() => handleReportsClick(event)}
                          >
                            {event.EVENT_TAG}
                          </td>
                          <td>
                            <a
                              href={event.IMAGE_URL}
                              target="_blank"
                              rel="noreferrer noopener"
                              ref={aref}
                            ></a>
                            {event.IMAGE_COUNT > 0 && (
                              <MButton
                                onClick={() => {
                                  aref.current && aref.current.click();
                                }}
                                title='View Images'
                                variant="primary"
                                width={"fit-content"}
                                // style={{ width: 130 }}
                              >
                                View Images
                              </MButton>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </>
        }
      />
      <ReportsModal
        show={showModal}
        event={event}
        lookback={lookback}
        accessToken={accessToken}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default RadiologyResults;
