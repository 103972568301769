import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPatientLabResults from "../types/IPatientLabResults";
import IPatientNotes from "../types/IPatientNotes";
import IPatientRadiologyResults from "../types/IPatientRadiologyResults";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
const getLabResults = async (
  data: {
    personId: string;
    lookback: string;
    encounterId?: string;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IPatientLabResults>(
    `${API_BASE_URL}/chart/labs`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getNotes = async (
  data: {
    eventId: string;
    personId: string;
    lookback: string;
    encounterId?: string;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IPatientNotes>(
    `${API_BASE_URL}/chart/notes`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getNotesHTML = async (
  data: {
    NOTE_CNT: number;
    NOTE_GROUP_PERFORMED_DATE: string;
    NOTE_GROUP_VERIFIED_DATE: string;
    NOTE_GROUP_TITLE: string;
    NOTE_GROUP_AUTHOR: string;
    NOTE_GROUP_AUTHOR_POSITION: string;
    PATIENT_NAME: string;
    DOB: string;
    NOTES: {
      EVENT_ID: string;
      NAME_FULL: string;
      POSITION: string;
      NOTE_PERFORMED_DATE: string;
      NOTE_SEQ: string;
    }[];
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  // /chart/noteshtml
  const result = await axios.post(`${API_BASE_URL}/chart/notespdf`, data, loginas !== null ? {
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Accept: 'application/pdf',
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    responseType: 'blob',
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      "Content-Type": "application/json",
      Accept: 'application/pdf',
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
    cancelToken: cancelToken?.token,
  });
  const pdfBlob = new Blob([result.data], { type: 'application/pdf' });
  const url = URL.createObjectURL(pdfBlob);
  return url;
};

const getNotesError = async (token?: string) => {
  const result = await axios.get(`${API_BASE_URL}/chart/noteserror`,loginas !== null ? {
    headers: {
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
  }:{
    headers: {
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
}

const getRadiologyResults = async (
  data: {
    personId: string;
    lookback: string;
    encounterId?: string;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IPatientRadiologyResults>(
    `${API_BASE_URL}/chart/radiology`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getReportsHTML = async (
  data: {
    FIN: string;
    FACILITY: string;
    ENCNTR_TYPE: string;
    DISCHARGE_DATE: string;
    CATALOG: string;
    ORDER_ID: number;
    EVENT_VISIT_DATE: string;
    EVENT_DATE: string;
    REPORT_STATUS: string;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post(`${API_BASE_URL}/chart/radiologyhtml`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

const ChartService = {
  getLabResults,
  getNotes,
  getNotesHTML,
  getRadiologyResults,
  getReportsHTML,
  getNotesError
};

export default ChartService;
